.no-bottom-border {
    border-bottom: "none" !important;
    border-bottom-width: 0 !important;
    border-bottom-color: transparent !important;
}

.no-bottom-border>* {
    border-bottom: "none" !important;
    border-bottom-width: 0 !important;
    border-bottom-color: transparent !important;
}