.main-headline {
    display: flex;
    justify-content: center;
    font-family: 'Cinzel';
    font-weight: 500;
}

.sub-headline {
    display: flex;
    justify-content: center;
    font-family: 'Cinzel';
    font-weight: 500;

}