.rings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rings-container > img {
    width: 350px;
}

.rings-names {
    position: absolute;
    font-family: 'Antro Vectra', cursive;
    -webkit-font-smoothing: antialiased;
    font-size: 45px;
    padding-top: 5px;
}

.rings-names-normal-fw {
    font-weight: normal;
}

.rings-date {
    display: flex;
    position: relative;
    font-family: 'Cinzel';
    /* font-size: 1.2rem; */
    justify-content: center;
    margin-bottom: 24px;
    font-weight: 200;
}